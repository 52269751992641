/**
 * Direttiva che sovrascrive il 'role' dell'md-list
 */

export interface IMdListDirectiveScope extends ng.IScope {
    customRole: string;
}

angular.module('app').directive('mdList', ($mdTheming) => {
    return {
        restrict: 'E',
        scope: {
            customRole: '@'
        },
        priority: 100, // fornisco una proprità maggiore
        link: function (scope: IMdListDirectiveScope, element, attr) {
            element[0].setAttribute('role', scope.customRole || '');
        }
    };
});